import React from 'react'

import { Box, Typography } from '@mui/material'
import {
  AllCommunityModule,
  ICellRendererParams,
  ModuleRegistry,
  themeQuartz,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { LeadCompany } from 'types/graphql'

import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

import OpportunityChip from '../common/OpportunityChip'
import PartnerChip from '../common/PartnerChip'

const ancilGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
})

interface Props {
  leads: AncillaryLead[]
}

export default function SummaryTable({ leads }: Props): React.ReactElement {
  ModuleRegistry.registerModules([AllCommunityModule])
  return (
    <div
      style={{ maxHeight: '50vh', overflow: 'scroll' }}
      className={`mb-5 mt-5 ${leads?.length > 2 ? '' : leads?.length === 1 ? 'h-[118px]' : 'h-[178px]'}`}
    >
      <AgGridReact
        columnDefs={[
          {
            headerName: 'Name',
            field: 'decryptedName',
            cellClass: 'flex items-center truncate',
            cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
              <span className={'truncate'}>{params.data.decryptedName}</span>
            ),
          },
          {
            headerName: 'Opportunity',
            field: 'triggerType',
            cellClass: 'flex items-center truncate',
            cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
              <OpportunityChip opportunity={params.data.triggerType} />
            ),
          },
          {
            headerName: 'Associated Address',
            field: 'propertyAddress',
            cellClass: 'flex items-center',
            cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
              <span className={'truncate'}>{params.data.propertyAddress}</span>
            ),
          },
          {
            colId: 'staffMembers',
            headerName: 'Staff Member(s)',
            field: 'extPrimaryAgentName',
            cellClass: 'flex items-center',
            cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
              <Box gap={2}>
                <Typography variant={'body2'}>
                  {params.data.extPrimaryAgentName || (
                    <Typography variant={'body2'} className={'text-gray-400'}>
                      {'Member not found'}
                    </Typography>
                  )}
                </Typography>
                {params.data.extSecondaryAgentName && (
                  <Typography variant={'body2'} className={'text-gray-400'}>
                    {params.data.extSecondaryAgentName}
                  </Typography>
                )}
              </Box>
            ),
          },
          {
            colId: 'leadCompany',
            headerName: 'Partner',
            field: 'leadCompany',
            cellClass: 'flex items-center',
            cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
              <PartnerChip partner={params.data.leadCompany as LeadCompany} />
            ),
          },
        ]}
        domLayout={leads?.length > 2 ? 'autoHeight' : undefined}
        rowData={leads}
        theme={ancilGridTheme}
        rowHeight={60}
        loadThemeGoogleFonts={false} // stops console warning
        suppressCellFocus // disables cell borders appearing on click
      />
    </div>
  )
}
