import React, { Dispatch, SetStateAction } from 'react'

import { ArchiveBoxIcon } from '@heroicons/react/24/outline'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { Box, Typography, Tooltip } from '@mui/material'
import { SelectionEventSourceType, SelectAllMode } from 'ag-grid-community'
import { Popconfirm } from 'antd'

import { toast } from '@redwoodjs/web/toast'

import { useArchiveLeads } from 'src/components/Ancil/hooks/useArchiveLeads'
import Button from 'src/components/Library/Button/Button'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

interface Props {
  leads: AncillaryLead[]
  selectedLeadIds: number[]
  deselectAll: (mode?: SelectAllMode, source?: SelectionEventSourceType) => void
  setIsBulkReferModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function BulkActionsPanel({
  selectedLeadIds,
  deselectAll,
  leads,
  setIsBulkReferModalOpen,
}: Props): React.ReactElement {
  const [archiveLeads, { loading }] = useArchiveLeads({
    onCompleted: () => {
      deselectAll()
      toast.success('Successfully archived leads')
    },
    awaitRefetchQueries: false,
  })
  const isBulkReferDisabled = leads
    .filter((lead) => selectedLeadIds.includes(lead.id))
    .some((lead) => lead.leadStatus !== 'NEW')

  const isArchiveDisabled = leads
    .filter((lead) => selectedLeadIds.includes(lead.id))
    .some((lead) => lead.leadStatus === 'ARCHIVED')

  return (
    <Box
      data-testid={'bulk-actions-panel'}
      display={'flex'}
      alignItems={'center'}
      gap={3}
      className={`bulk-actions-panel fixed bottom-[15px] left-1/2 -translate-x-1/2 rounded-md bg-white p-3 px-5 shadow-md ring-1 ring-gray-900/5 ${selectedLeadIds.length === 0 ? 'invisible' : ''}`}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'body2'} className={'text-gray-500'}>
          {`${selectedLeadIds.length} Leads Selected`}
        </Typography>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          className={'ml-2 cursor-pointer text-gray-500 hover:text-indigo-500'}
          onClick={() => deselectAll()}
        >
          {'×'}
        </span>
      </Box>
      <Box display={'flex'} gap={1}>
        <Tooltip
          title={
            isBulkReferDisabled
              ? 'Some of the selected leads have already been referred'
              : ''
          }
        >
          <div>
            <Button
              variant={'outlined'}
              onClick={() => setIsBulkReferModalOpen(true)}
              className={
                'border-zinc-300 py-[4px] text-zinc-700 hover:border-indigo-500 hover:bg-white hover:text-indigo-500'
              }
              disabled={isBulkReferDisabled}
            >
              <ForwardToInboxIcon className={'mr-2 h-4 w-4'} />
              {'Refer Leads'}
            </Button>
          </div>
        </Tooltip>
        <Popconfirm
          title={'Are you sure you want to archive these leads?'}
          description={'You can restore these leads later'}
          okButtonProps={{ loading }}
          onConfirm={() =>
            archiveLeads({
              variables: { ids: selectedLeadIds },
            })
          }
        >
          <Tooltip
            title={isArchiveDisabled ? 'Some leads are already archived' : ''}
          >
            <div>
              <Button
                variant={'outlined'}
                className={
                  'min-w-[150px] border-zinc-300 py-[4px] text-zinc-700 hover:border-indigo-500 hover:bg-white hover:text-indigo-500'
                }
                disabled={isArchiveDisabled}
              >
                <ArchiveBoxIcon className={'mr-2 h-4 w-4'} />
                {'Archive Leads'}
              </Button>
            </div>
          </Tooltip>
        </Popconfirm>
      </Box>
    </Box>
  )
}
